import React from 'react'
import './secThreeCard.css'
import {Theme, Language} from '../../App'
import {useContext} from 'react'

function IconCard(props) {
    const iconSelected = props.iconSelected
    const languageCardIcon = <i class="secThreeIconSize fas fa-language"></i>
    const technicalKnowledge = <i class="secThreeIconSize fas fa-laptop"></i>
    const professionalKnowledge = <i class="secThreeIconSize fas fa-users"></i>
    const professionalSkills = <i class="secThreeIconSize fas fa-hands-helping"></i>

    if(iconSelected == "1") {
        return languageCardIcon
    } else if(iconSelected == "2") {
        return technicalKnowledge
    } else if(iconSelected == "3") {
        return professionalKnowledge
    } else if(iconSelected == "4") {
        return professionalSkills
    } else {
        return null
    }

}

function SecThreeCard(props) {
    
    const {darkTheme, setDarkTheme} = useContext(Theme)
    const {language, setLanguage} = useContext(Language)

    return(

        <div className = {`cardContainer ${darkTheme ? 'secThreeCardDarkTheme' : ''}`}>
            <div className = "cardIconContainer">

                {<IconCard iconSelected = {props.iconIndex} />}

            </div>
            <div className = "cardContentContainer">
                <h2 className = "secThreeCardTitle">{props.title}</h2>
                <p className = "secThreeCardSkill">{props.skillOne}</p>
                <p className = "secThreeCardSkill">{props.skillTwo}</p>
                <p className = "secThreeCardSkill">{props.skillThree}</p>
            </div>
        </div>
    )
}

export default SecThreeCard