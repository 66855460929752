// import { Component } from 'react';
import './sectionOne.css'
import { useContext, useState } from 'react';
import {Theme, Language} from '../../App'

function ContentLang(props) {
    const lang = props.lang
    if(lang == "ESP") {
        return "Descargar CV"
    } else if (lang == "ENG") {
        return "Download CV"
    } else if (lang == "ITA") {
        return "Scarica CV"
    } else {
        return null
    }
}

function LanguageMenuLinks (props) {
    const langMenuESP = ["Español", "Inglés", "Italiano"]
    const langMenuENG = ["Spanish", "English", "Italian"]
    const langMenuITA = ["Spagnolo", "Inglese", "Italiano"]

    const menuLang = props.menuLang

    if(menuLang == "ESP") {
        return langMenuESP[props.index]
    } else if(menuLang == "ENG") {
        return langMenuENG[props.index]
    } else if(menuLang == "ITA") {
        return langMenuITA[props.index]
    } else {
        return null
    }
}


function SecOne() {

    const {darkTheme, setDarkTheme} = useContext(Theme)
    const {language, setLanguage} = useContext(Language)

    const [showLangMenu, setLangMenu] = useState(false)

    const langMenu = () => {
        setLangMenu(showLangMenu => !showLangMenu);
    }

    const changeLanguageToESP = () => {
        setLanguage("ESP")
        setLangMenu(showLangMenu => !showLangMenu);
    }

    const changeLanguageToENG = () => {
        setLanguage("ENG")
        setLangMenu(showLangMenu => !showLangMenu);
    }

    const changeLanguageToITA = () => {
        setLanguage("ITA")
        setLangMenu(showLangMenu => !showLangMenu);
    }

    return(
        <div className = {`bodySecOne ${darkTheme ? 'darkTheme' : 'ligthTheme'}`}>

            <div className = "wrapUpSecOne">
                <div className = "subSecI">
                    {darkTheme ? <i onClick = {() => setDarkTheme(!darkTheme)} class="iconSize fas fa-sun"></i> : <i onClick = {() => setDarkTheme(!darkTheme)} class="iconSize nightIcon uil uil-moon"></i>}

                    <div className = "languageCont">
                        <i onClick = {langMenu} class="iconSize languageIcon ui uil-english-to-chinese"></i>

                        <ul className = {`languageMenu ${showLangMenu ? 'showOn' : ''}`}>
                            <li onClick = {changeLanguageToESP} className = "langItems"><LanguageMenuLinks menuLang = {language} index ={0}/></li>
                            <li onClick = {changeLanguageToENG} className = "langItems"><LanguageMenuLinks menuLang = {language} index ={1}/></li>
                            <li onClick = {changeLanguageToITA} className = "langItems"><LanguageMenuLinks menuLang = {language} index ={2}/></li>
                        </ul>
                    </div>


                </div>

                <div className = "subSecII">
                    {/* <div className = "profilePicture"></div> */}
                    <img src = {process.env.PUBLIC_URL + 'assets/profilePicture.png'} className = {`profilePicture ${darkTheme ? 'profileBackgroundDark' : 'profileBackgroundLight'}`} alt = "profile:picture"/>
                </div>

                <div className = "subSecIII">
                    <h1 className = "profileName">Julián Valero</h1>
                    <a download = "" href = {process.env.PUBLIC_URL + 'assets/Julián_Valero-CV.pdf'} className = "cvLink"><ContentLang lang = {language} /></a>
                </div>

                <div className = "subSecIV">
                    <div className = {`${darkTheme ? 'scrollIconDark' : 'scrollIconLight'}`}></div>
                </div>
            </div>

        </div>

    )
}

export default SecOne;