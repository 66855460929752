import React from 'react'
import { useState, useContext } from 'react'
import { Theme, Language } from '../App'
import './footer.css'
import '../nav/logo.css'

function Footer() {

    const {darkTheme, setDarkTheme} = useContext(Theme)
    const {language, setLanguage} = useContext(Language)

    let footerTexts = {
        footerTitle: '',
        footerMsg: '',
        footerFollowMe: '',
        footerMadeIn: ''
    }

    if(language == "ESP") {
        footerTexts.footerTitle = "Contacto"
        footerTexts.footerMsg = "Sientete libre de contactarme en cualquier momento."
        footerTexts.footerFollowMe = "Sígueme en"
        footerTexts.footerMadeIn = "Hecho con ♥ en Bogotá, Colombia"
    } else if(language == "ENG") {
        footerTexts.footerTitle = "Contact"
        footerTexts.footerMsg = "Feel free to reach out to me anytime."
        footerTexts.footerFollowMe = "Follow me"
        footerTexts.footerMadeIn = "Made with ♥ in Bogotá, Colombia"
    } else if(language == "ITA") {
        footerTexts.footerTitle = "Contactto"
        footerTexts.footerMsg = "Sentiti libero di contattarmi in qualsiasi momento."
        footerTexts.footerFollowMe = "Seguimi su"
        footerTexts.footerMadeIn = "Fatto con ♥ in Bogotá, Colombia"
    }
    
    return(
        // 
        <div className = {`bodyFooter ${darkTheme ? 'darkTheme' : ''}`}>
            <div className = "wrapUpFooter">

                <div className = "contactContainer">
                    <div className = "conatctInfo">
                        <h2>{footerTexts.footerTitle}</h2>
                        <p className = "contactInfoMsg">{footerTexts.footerMsg}</p>
                        <i class="far fa-envelope contactIcons"></i><span>jdavid0396@gmail.com</span>
                        <br />
                        <i class="fas fa-phone-alt contactIcons"></i><span>+57 301 642-1067</span>
                    </div>
                    <div className = "socialMediaContainer">
                        <h3>{footerTexts.footerFollowMe}</h3>
                        <div className = "socialMediaIcons">
                            <a className = "socialMediaIconLink" href = "https://www.linkedin.com/in/julian-valero-3b6226170/" target = "_blank">
                                <i class="fab fa-linkedin-in smIconsSize"></i>
                            </a>
                            <a className = "socialMediaIconLink" href = "https://github.com/Jul472" target = "_blank">
                                <i class="fab fa-github smIconsSize"></i>
                            </a>
                            <a className = "socialMediaIconLink" href = "" target = "_blank">
                                <i class="fab fa-whatsapp smIconsSize"></i>
                            </a>
                            <a className = "socialMediaIconLink" href = "" target = "_blank">
                                <i class="fab fa-telegram-plane smIconsSize"></i>
                            </a>
                        </div>
                    </div>
                </div>
                
                <div className = {`creditsContainer ${darkTheme ? 'creditsContainerDark' : 'creditsContainerLight'}`}>
                    <div className = {`portfolioLogo ${darkTheme ? 'logoBorderDark' : 'logoBorderLight'}`}>JV</div>
                    <div className = "credits">
                        <span>© 2021 Julián Valero</span>
                        <br />
                        <span>{footerTexts.footerMadeIn}</span>
                    </div>
                </div>
            </div>
        </div>  
    )
}

export default Footer