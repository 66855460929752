import React from 'react'
import { useState, useContext } from 'react'
import { Theme, Language } from '../../App'
import './sectionThree.css'
import SecThreeCard from './secThreeCard'

function SecThree() {
    
    const {darkTheme, setDarkTheme} = useContext(Theme)
    const {language, setLanguage} = useContext(Language)

    let cardContent= {
        secThreeTitle: '',
        title:'',
        skillOne: '',
        skillTwo: '',
        skillThree: '',

    }

    if(language == "ESP") {
        cardContent.secThreeTitle = 'Habilidades'
        cardContent.title =      ['Idiomas' , 'Conocimientos Técnicos', 'Conocimientos Profesionales'  , 'Competencias Profesionales']
        cardContent.skillOne =   ['Español' , 'HTML, CSS, JS'            , 'Teoría del Estado'            , 'Habilidad Matemática']
        cardContent.skillTwo =   ['Inglés'  , 'GitHub'                , 'Política Economía'            , 'Análisis Descriptivo']
        cardContent.skillThree = ['Italiano', 'Microsoft Office'      , 'Instituciones Internacionales', 'Empatía']

    } else if(language == "ENG"){
        cardContent.secThreeTitle = 'Skills'
        cardContent.title =      ['Languages' , 'Technical Knowledge' , 'Professional Knowledge'   , 'Professional Skills']
        cardContent.skillOne =   ['Spanish'   , 'HTML, CSS, JS'          , 'State Theory'             , 'Mathematical Ability']
        cardContent.skillTwo =   ['English'   , 'GitHub'              , 'Political Economics'      , 'Descriptive Analysis']
        cardContent.skillThree = ['Italian'   , 'Microsoft Office'    , 'International Institutions', 'Empathy']

    } else if(language == "ITA"){
        cardContent.secThreeTitle = 'Abilità'
        cardContent.title =      ['Lingue'   , 'Conoscenze Tecniche' , 'Conoscenza Professionale'   , 'Abilità Professionale']
        cardContent.skillOne =   ['Spagnolo' , 'HTML, CSS, JS'          , 'Teoria dello Stato'         , 'Abilità Matematiche']
        cardContent.skillTwo =   ['Inglese'  , 'GitHub'              , 'Economia Politica'          , 'Analisi Descrittivo']
        cardContent.skillThree = ['Italiano' , 'Microsoft Office'    , 'Istituzioni Internazionali' , 'Empatia']
    }


    return(
        <div className = {`bodySecThree ${darkTheme ? 'secThreeDarkTheme' : ''}`}>
            <div className = "wrapUpSecThree">
                <h2>{cardContent.secThreeTitle}</h2>

                <div className = "secThreeCardsContainer">

                    <div className = "secThreeCardsContainerSubOne">
                        {/* <SecThreeCard title = "Idiomas" skillOne ="Español" skillTwo = "Inglés" skillThree = "Italiano" iconIndex = "1"/> */}
                        <SecThreeCard title = {cardContent.title[0]} skillOne = {cardContent.skillOne[0]} skillTwo = {cardContent.skillTwo[0]} skillThree = {cardContent.skillThree[0]} iconIndex = "1"/>
                        
                        <SecThreeCard title = {cardContent.title[1]} skillOne = {cardContent.skillOne[1]} skillTwo = {cardContent.skillTwo[1]} skillThree = {cardContent.skillThree[1]} iconIndex = "2"/>
                    </div>

                    <div className = "secThreeCardsContainerSubTwo">
                        <SecThreeCard title = {cardContent.title[2]} skillOne = {cardContent.skillOne[2]} skillTwo = {cardContent.skillTwo[2]} skillThree = {cardContent.skillThree[2]} iconIndex = "3"/>

                        <SecThreeCard title = {cardContent.title[3]} skillOne = {cardContent.skillOne[3]} skillTwo = {cardContent.skillTwo[3]} skillThree = {cardContent.skillThree[3]} iconIndex = "4"/>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default SecThree