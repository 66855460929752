import './sectionTwo.css'
import { useState, useContext } from 'react'
import {Theme, Language} from '../../App'

function AboutMeTitle(props) {
    const lang = props.lang
    if(lang == "ESP") {
        return "Acerca de Mi"
    } else if(lang == "ENG"){
        return "About Me"
    } else if(lang == "ITA") {
        return "Su di Me"
    } else {
        return null
    }
}

function SecTwo() {
    
    const {darkTheme, setDarkTheme} = useContext(Theme)
    const {language, setLanguage} = useContext(Language)

    let presentationText

    if(language == "ESP") {
        presentationText = "Esmerado y confiable profesional con habilidades analíticas acompañadas de una capacidad de aprendizaje rápido. Experiencia en resolución de problemas y asistencia para el área administrativa. Aptitudes para la comunicación de trabajo en equipo a través de la empatía y el uso de enfoques de conciliación."
    } else if(language == "ENG") {
        presentationText = "Conscientious and reliable professional with analytical driven skills accompanied by a fast-learning ability. Experience in problem solving and assistance for administrative purposes. Aptitudes for teamwork communication through empathy and conciliation approaches."
    } else if(language == "ITA") {
        presentationText = "Professionista affidabile con capacità analitiche accompagnate da una capacità di apprendimento rapido. Esperienza nella risoluzione di problemi e assistenza per scopi amministrativi. Attitudine alla comunicazione in team attraverso approcci empatici e conciliativi."
    } else {
        return null
    }

    return(
        <div className = {`bodySecTwo ${darkTheme ? 'darkThemeSecTwo' : ''}`}>
            <div className = "wrapUpSecTwo">
                <h2 className = "aboutMeTitle"><AboutMeTitle lang = {language}/></h2>
                <p className = "aboutMeText">{presentationText}</p>


                {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae, aperiam. Fugiat rerum expedita beatae ipsum optio, nostrum nobis culpa, in amet ipsam laudantium dolor esse dolorem sint fugit alias accusamus? */}
            </div>
        </div>
    )

}

export default SecTwo;