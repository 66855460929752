import './nav.css'
import './logo.css'
import { Theme, Language } from '../App';
import {useContext, useState} from 'react';

function NavLinks(props) {
    const navLinksESP = ["Acerca de Mi", "Habilidades", "Proyectos Personales", "Contacto"]
    const navLinksENG = ["About Me", "Skills", "Personal Projects", "Contact Me"]
    const navLinksITA = ["Su di Me", "Abilità", "Progetti Personali", "Contactto"]
    const lang = props.lang
    if(lang == "ESP") {
        return navLinksESP[props.index]
    } else if(lang == "ENG") {
        return navLinksENG[props.index]
    } else if(lang == "ITA") {
        return navLinksITA[props.index]
    } else {
        return null
    }
}

function NavBar () {

    const [showMenu, setMenu] = useState(false)

    const {darkTheme, setDarkTheme} = useContext(Theme)
    const {language, setLanguage} = useContext(Language)

    const clickToShowMenu = () => {
        setMenu(showMenu => !showMenu)
    }

    const secIDiv = document.getElementsByClassName("bodySecOne")
    const secIIDiv = document.getElementsByClassName("bodySecTwo")
    const secIIIDiv = document.getElementsByClassName("bodySecThree")
    const secIVDiv = document.getElementsByClassName("bodySecFour")

    return (

        <div className = {`navContainer ${darkTheme ? 'darkTheme' : 'ligthTheme'}`}>

            <div className = {`navBar ${darkTheme ? 'darkTheme': ''}`}>
                <div onClick = {() => {window.scrollTo(0, 0)}}>
                    <div className = {`portfolioLogo ${darkTheme ? 'logoBorderDark' : 'logoBorderLight'}`}>JV</div>
                </div>

                <ul className = {`linkList ${showMenu ? 'showOn': ''}`}>
                    <li className = "linkCont">
                        <a onClick = {() => {window.scrollTo(0, secIDiv[0].clientHeight)}} className = "link"><NavLinks lang = {language} index = {0}/></a> 
                    </li>
                    <li className = "linkCont">
                        <a onClick = {() => {window.scrollTo(0, secIDiv[0].clientHeight + secIIDiv[0].clientHeight)}} className = "link"><NavLinks lang = {language} index = {1}/></a>
                    </li>
                    <li className = "linkCont">
                        <a onClick = {() => {window.scrollTo(0, secIDiv[0].clientHeight + secIIDiv[0].clientHeight + secIIIDiv[0].clientHeight)}} className = "link"><NavLinks lang = {language} index = {2}/></a>
                    </li>
                    <li className = "linkCont">
                        <a onClick = {() => {window.scrollTo(0, secIDiv[0].clientHeight + secIIDiv[0].clientHeight + secIIIDiv[0].clientHeight + secIVDiv[0].clientHeight)}} className = "link"><NavLinks lang = {language} index = {3}/></a>
                    </li>
                </ul>
                <div>
                    <i onClick = {clickToShowMenu} id = "iconMenu" class="iconSize iconMenu uit uit-grip-horizontal-line"></i>
                </div>
            </div>

        </div>

    )

    
}

export default NavBar;