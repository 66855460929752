import React from 'react'
import { useState, useContext } from 'react'
import { Theme, Language } from '../../App'
import './sectionFour.css'

let carouselItemIndex = 1
let carouselContainerSecFour = document.getElementsByClassName('carouselContainer')

function SecFour() {
    
    let [translateInX, setTranslateInX] = useState(0)

    const {darkTheme, setDarkTheme} = useContext(Theme)
    const {language, setLanguage} = useContext(Language)

    let carouselTexts = {
        projectOneText: '',
        projectTwoText: '',
        projectThreeText: '',
    }

    let secFourTitle;

    const carouselTransition = {
        transform: `translateX(${translateInX}vw)`,
        transition: 'transform 0.25s',
    }

    const clickToTransitionRight = () => {

        const translationWidth = (carouselContainerSecFour[0].clientWidth * 100)/window.innerWidth

        if(carouselItemIndex == 1) {
            setTranslateInX(translateInX -= translationWidth)
            console.log(window.innerWidth)
            // setTranslateInX(translateInX -= carouselContainerSecFour[0].clientWidth)
            carouselItemIndex += 1
        } else if(carouselItemIndex == 2) {
            // setTranslateInX(translateInX -= carouselContainerSecFour[0].clientWidth)
            setTranslateInX(translateInX -= translationWidth)
            carouselItemIndex += 1
        } else if(carouselItemIndex == 3){
            setTranslateInX(translateInX = 0)
            carouselItemIndex = 1
        }
    }

    const clickToTransitionLeft = () => {

        const translationWidth = (carouselContainerSecFour[0].clientWidth * 100)/window.innerWidth

        if(carouselItemIndex == 1) {
            setTranslateInX(translateInX = 0)
        } else if(carouselItemIndex == 2) {
            setTranslateInX(translateInX += translationWidth)
            carouselItemIndex -= 1
        } else if(carouselItemIndex == 3){
            setTranslateInX(translateInX += translationWidth)
            carouselItemIndex -= 1
        }
    }
    
    if(language == "ESP") {
        secFourTitle = "Proyectos Personales"
        carouselTexts.projectOneText = "Este proyecto muestra visualmente atractores, como el conocido atractor de Lorenz, que se encuentran descritos dentro del campo de estudio de los sistemas dinámicos No-lineares."
        carouselTexts.projectTwoText = "Este proyecto utiliza el algoritmo A* para determinar el camino más corto entre dos puntos. Está diseñado para funcionar en un ordenador."
        carouselTexts.projectThreeText = "Este Proyecto utiliza la multiplicación de matrices para proyectar un objecto de 3 dimensiones (Cubo) y un objeto de 4 dimensiones (Teseracto) en un espacio de 2 dimensiones."
    } else if(language == "ENG") {
        secFourTitle = "Personal Projects"
        carouselTexts.projectOneText = "This project visually shows attractors, such as the well-known Lorenz attractor, which are described within the field of study Non-linear dynamical systems."
        carouselTexts.projectTwoText = "This project uses the A* algorithm to determine the shortest path between two points. It is designed to work on a desktop computer."
        carouselTexts.projectThreeText = "This Project uses matrix multiplication to project a 3-dimensional object (Cube) and a 4-dimensional object (Tesseract) in a 2-dimensional space."
    } else if(language == "ITA") {
        secFourTitle = "Progetti Personali"
        carouselTexts.projectOneText = "Questo progetto mostra visivamente gli attrattori, come il conosciuto attrattore di Lorenz, che sono descritti nell'ambito dello studio dei sistemi dinamici non lineari."
        carouselTexts.projectTwoText = "Questo progetto utilizza l'algoritmo A* per determinare il percorso più breve tra due punti. È progettato per funzionare su un computer."
        carouselTexts.projectThreeText = "Questo progetto utilizza la moltiplicazione matriciale per proiettare un oggetto tridimensionale (Cubo) e un oggetto quadridimensionale (Tesseract) in uno spazio bidimensionale."
    }

    return(

        
        <div className = {`bodySecFour ${darkTheme ? 'darkTheme' : ''}`}>

            <h2 className = "secFourTitle">{secFourTitle}</h2>

            <div className="carouselContainer">

                <i onClick = {clickToTransitionLeft} className="fas fa-angle-left carouselBtn left"></i>

                <div style = {carouselTransition} className="carouselBody">

                    <div className="carouselItem">

                        <img className = "carouselItemImage" src = {process.env.PUBLIC_URL + 'assets/ProjectOne.png'} />
                        <div className = "carouselItemDescription">
                            <span className = "carouselItemText">{carouselTexts.projectOneText}</span>
                            <a href = "https://jul472.github.io/ChaosModelsVisualizer/" target = "_blank" className = {`carouselItemBtn ${darkTheme ? 'carouselItemBtnDark' : 'carouselItemBtnLight'}`}>Demo</a>
                        </div>

                    </div>


                    <div className="carouselItem">
                        <img className = "carouselItemImage" src = {process.env.PUBLIC_URL + 'assets/ProjectTwo.png'} />
                        <div className = "carouselItemDescription">
                            <span className = "carouselItemText">{carouselTexts.projectTwoText}</span>
                            <a href = "https://jul472.github.io/PathFinder/" target = "_blank" className = {`carouselItemBtn ${darkTheme ? 'carouselItemBtnDark' : 'carouselItemBtnLight'}`}>Demo</a>
                        </div>
                      
                    </div>

                    <div className="carouselItem">

                        <img className = "carouselItemImage" src = {process.env.PUBLIC_URL + 'assets/ProjectThree.png'} />
                        <div className = "carouselItemDescription">
                            <span className = "carouselItemText">{carouselTexts.projectThreeText}</span>
                            <a href = "https://jul472.github.io/3D_4D_ObjectVisualizer/" target = "_blank" className = {`carouselItemBtn ${darkTheme ? 'carouselItemBtnDark' : 'carouselItemBtnLight'}`}>Demo</a>
                        </div>
                    </div>
                </div>

                <i onClick = {clickToTransitionRight} class="fas fa-angle-right carouselBtn right"></i>

            </div>

        </div>
    )
}

export default SecFour