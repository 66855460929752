// import logo from './logo.svg';
import React, {useState} from 'react';
import './App.css';
import NavBar from './nav/nav';
import SecOne from './sections/sectionOne/sectionOne';
import SecTwo from './sections/sectionTwo/sectionTwo';
import SecThree from './sections/sectionThree/sectionThree';
import SecFour from './sections/sectionFour/sectionFour';
import Footer from './footer/footer'

export const Theme = React.createContext()
export const Language = React.createContext()

function App() {

  const [darkTheme, setDarkTheme] = useState(false)
  const [language, setLanguage] = useState("ESP")

  return (
    <Theme.Provider value={{darkTheme, setDarkTheme}}>
      <Language.Provider value = {{language, setLanguage}}>   
        <NavBar />
        <SecOne />
        <SecTwo />
        <SecThree />
        <SecFour />
        <Footer />
      </Language.Provider>
    </Theme.Provider>
  );
}

export default App;
